import styled, { css } from "styled-components"
import { media } from "utils/Media"

const DownloadButton = styled.a`

    width: 100%;
    
    @media ${media.sm} {
        width: 198px;
    }

    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.red};
    transition: all 150ms ease-in-out;
    text-decoration: none;
    
    &:hover {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.black};
    }
    
    img {
        margin-left: 10px;
    }
`

export default DownloadButton