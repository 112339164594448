import React, {Component} from "react"
import styled from 'styled-components'
import {Container} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby'

import Layout from "components/Layout/Layout"
import VideoModal from "components/shared/VideoModal"
import DownloadButton from "components/shared/DownloadButton"
import ViewButton from "components/shared/ViewButton"
import "animate.css/animate.min.css"
import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image"
import IconDownload from 'images/icons/icon-download-white.svg'
import IconQuestion from 'images/icons/icon-question.svg'
import TooltipArrow from 'images/icons/tooltip-curve_arrow.svg'

const TopImage = styled.h1`
    margin: 0;
`

const BlockSection = styled.div`
    @media ${media.md} {
        display: flex;
        justify-content: center;
    }
`

const Block = styled.div`
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid ${props => props.theme.colors.superLightGrey};
    
    @media ${media.md} {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        width: 50%;
        
        &.border {
            &-br {
                border-bottom: 1px solid ${props => props.theme.colors.superLightGrey};
                border-right: 1px solid ${props => props.theme.colors.superLightGrey};
                padding-bottom: 2rem;
                padding-right: 2rem;
                
                &:hover {
                    background: linear-gradient(135deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
            
            &-bl {
                border-bottom: 1px solid ${props => props.theme.colors.superLightGrey};
                border-left: 1px solid ${props => props.theme.colors.superLightGrey};
                padding-bottom: 2rem;
                padding-left: 2rem;
                
                &:hover {
                    background: linear-gradient(225deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
            
            &-tr {
                border-top: 1px solid ${props => props.theme.colors.superLightGrey};
                border-right: 1px solid ${props => props.theme.colors.superLightGrey};
                padding-top: 2rem;
                padding-right: 2rem;
                
                &:hover {
                    background: linear-gradient(45deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
            
            &-tl {
                border-top: 1px solid ${props => props.theme.colors.superLightGrey};
                border-left: 1px solid ${props => props.theme.colors.superLightGrey};
                padding-top: 2rem;
                padding-left: 2rem;
                
                &:hover {
                    background: linear-gradient(315deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
        }
    }
    
    h2 {
        margin-top: 2rem;
    }
    
    .interactive-mode {
        // display: none;
    }
`

const Tooltip = styled.div`
    position: relative;
    opacity: 1;
    
    .tooltip-inner {
        visibility: hidden;
        width: 100%;
        background-color: ${props => props.theme.colors.yellow};
        color: #fff;
        padding: 1rem;
        position: absolute;
        z-index: 2;
        bottom: calc(100% + 1rem);
        left: 50%;
        margin-left: -50%;
        opacity: 0;
        transition: opacity 150ms ease-in-out;
        color: ${props => props.theme.colors.black};
        
         @media ${media.lg} {
            width: 198px;
            margin-left: -99px;
            bottom: 100%;
         }
        
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            width: 49px;
            height: 10px;
            transform: translateX(-50%);
            background: url(${TooltipArrow}) no-repeat center bottom;
        }    
    }
    
    &.active .tooltip-inner {
        visibility: visible;
        opacity: 1;
    }
    
    @media ${media.lg} {
        &:hover .tooltip-inner {
            visibility: visible;
            opacity: 1;
        }
    }
   
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                HomeHeaderXs: file(relativePath: { eq: "HomePage/home-header-575.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                HomeHeaderXl: file(relativePath: { eq: "HomePage/home-header-1600.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1600, quality: 100)
                    }
                }
                
                StoryXs: file(relativePath: { eq: "HomePage/story-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 576, quality: 100)
                    }
                }
                
                StoryMd: file(relativePath: { eq: "HomePage/story-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                CharacterXs: file(relativePath: { eq: "HomePage/character-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 576, quality: 100)
                    }
                }
                
                CharacterMd: file(relativePath: { eq: "HomePage/character-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                ProductionXs: file(relativePath: { eq: "HomePage/production-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 576, quality: 100)
                    }
                }
                
                ProductionMd: file(relativePath: { eq: "HomePage/production-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                FactXs: file(relativePath: { eq: "HomePage/fact-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 576, quality: 100)
                    }
                }
                
                FactMd: file(relativePath: { eq: "HomePage/fact-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <IndexPage data={data}/>
        )}
    />
)

class IndexPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            tooltip1: false,
            tooltip2: false,
            tooltip3: false,
            tooltip4: false,
        }
    }

    componentDidMount() {

    }

    toggleTooltip = (e) => {
        const collapse = e.currentTarget.id;
        this.setState({
            [collapse]: !this.state[collapse]
        })
    }

    toggleModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            modalOpen: !this.state.modalOpen
        });
    }

    render() {

        return (
            <Layout title="Welcome">
                <TopImage>
                    <GatsbyImage image={this.props.data.HomeHeaderXs.childImageSharp.gatsbyImageData}
                        alt="The Lion King Education Programme"
                        title="The Lion King Education Programme"
                        className="img-fluid d-block d-sm-none"
                    />
                    <GatsbyImage image={this.props.data.HomeHeaderXl.childImageSharp.gatsbyImageData}
                        alt="The Lion King Education Programme"
                        title="The Lion King Education Programme"
                        className="img-fluid d-none d-sm-block"
                    />
                </TopImage>

                <Container fluid={true} className="my-4">
                    <Container className="no-padding-xs">
                        <BlockSection>
                            <Block className="border-br">
                                <GatsbyImage image={this.props.data.StoryXs.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-block d-lg-none"
                                />
                                <GatsbyImage image={this.props.data.StoryMd.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-none d-lg-block"
                                />
                                <h2>The Story</h2>

                                <p>Enjoy a summary of the full story of The Lion King from start to finish.</p>

                                <div className="d-block d-sm-flex d-md-block d-lg-flex left-aligned">
                                    <Tooltip className="tooltip interactive-mode mr-lg-3">
                                        <ViewButton href="/interactive/the-story">View interactive mode</ViewButton>

                                        <div className="d-none d-lg-block tooltip-inner">
                                            <p><img src={IconQuestion} alt=""/> <strong className="text-uppercase">Interactive mode</strong></p>
                                            <p>This experience is designed specifically for your students
                                                in or outside of the classroom.</p>
                                        </div>
                                    </Tooltip>

                                    {/*<DownloadButton><span className="d-none d-lg-inline">Download&nbsp;</span>PDF <img src={IconDownload} alt="" /></DownloadButton>*/}
                                    <DownloadButton download target="_blank" href="/pdfs/Disneys_The_Lion_King_The_Story.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
                                </div>
                            </Block>

                            <Block className="border-bl">
                                <GatsbyImage image={this.props.data.CharacterXs.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-block d-lg-none"
                                />
                                <GatsbyImage image={this.props.data.CharacterMd.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-none d-lg-block"
                                />
                                <h2>Meet the Characters</h2>

                                <p>Get to know the cast of characters who make the story come to life!</p>

                                <div className="d-block d-sm-flex d-md-block d-lg-flex left-aligned">
                                    <Tooltip className="tooltip interactive-mode mr-lg-3">
                                        <ViewButton href="/interactive/meet-the-characters">View interactive mode</ViewButton>

                                        <div className="d-none d-lg-block tooltip-inner">
                                            <p><img src={IconQuestion} alt=""/> <strong className="text-uppercase">Interactive mode</strong></p>
                                            <p>This experience is designed specifically for your students
                                                in or outside of the classroom.</p>
                                        </div>
                                    </Tooltip>

                                    {/*<DownloadButton><span className="d-none d-lg-inline">Download&nbsp;</span>PDF <img src={IconDownload} alt="" /></DownloadButton>*/}
                                    <DownloadButton download target="_blank" href="/pdfs/Disneys_The_Lion_King_Meet_The_Characters.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
                                </div>
                            </Block>
                        </BlockSection>

                        <BlockSection>
                            <Block className="border-tr">
                                <GatsbyImage image={this.props.data.ProductionXs.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-block d-lg-none"
                                />
                                <GatsbyImage image={this.props.data.ProductionMd.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-none d-lg-block"
                                />
                                <h2>About the Production</h2>

                                <p>Explore this in-depth description of how the production was created.</p>

                                <div className="d-block d-sm-flex d-md-block d-lg-flex left-aligned">
                                    <Tooltip className="tooltip interactive-mode mr-lg-3">
                                        <ViewButton href="/interactive/about-the-production">View interactive mode</ViewButton>

                                        <div className="d-none d-lg-block tooltip-inner">
                                            <p><img src={IconQuestion} alt=""/> <strong className="text-uppercase">Interactive mode</strong></p>
                                            <p>This experience is designed specifically for your students
                                                in or outside of the classroom.</p>
                                        </div>
                                    </Tooltip>

                                    {/*<DownloadButton><span className="d-none d-lg-inline">Download&nbsp;</span>PDF <img src={IconDownload} alt="" /></DownloadButton>*/}
                                    <DownloadButton download target="_blank" href="/pdfs/Disney_The-Lion-King_Production_Notes.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
                                </div>
                            </Block>

                            <Block className="border-tl">
                                <GatsbyImage image={this.props.data.FactXs.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-block d-lg-none"
                                />
                                <GatsbyImage image={this.props.data.FactMd.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="img-fluid d-none d-lg-block"
                                />
                                <h2>Fascinating Facts</h2>

                                <p>Test your knowledge and learn something new with these fun facts about the show!</p>

                                <div className="d-block d-sm-flex d-md-block d-lg-flex left-aligned">
                                    <Tooltip className="tooltip interactive-mode mr-lg-3">
                                        {/*<ViewButton href="/interactive/fascinating-facts">View interactive mode</ViewButton>*/}

                                        <div className="d-none d-lg-block tooltip-inner">
                                            <p><img src={IconQuestion} alt=""/> <strong className="text-uppercase">Interactive mode</strong></p>
                                            <p>This experience is designed specifically for your students
                                                in or outside of the classroom.</p>
                                        </div>
                                    </Tooltip>

                                    {/*<DownloadButton><span className="d-none d-lg-inline">Download&nbsp;</span>PDF <img src={IconDownload} alt="" /></DownloadButton>*/}
                                    <DownloadButton download target="_blank" href="/pdfs/Disneys_The_Lion_King_Fascinating_Facts.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
                                </div>
                                <Tooltip className={`tooltip interactive-mode d-lg-none mt-4 ${this.state.tooltip4 && 'active'}`}>
                                    <div className="color--red text--underline text-center" onClick={this.toggleTooltip} id="tooltip4">
                                        <div className="d-lg-none">
                                        {this.state.tooltip4 ?
                                            <span>Tap to close</span>
                                        :
                                            <span>What is interactive mode?</span>
                                        }
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <span>What is interactive mode?</span>
                                        </div>
                                    </div>

                                    <div className="d-lg-none tooltip-inner">
                                        <p><img src={IconQuestion} alt=""/> <strong className="text-uppercase">Interactive mode</strong></p>
                                        <p>This experience is designed specifically for your students
                                            in or outside of the classroom.</p>
                                    </div>
                                </Tooltip>
                            </Block>
                        </BlockSection>
                    </Container>
                </Container>

                <VideoModal toggleModal={this.toggleModal} src={this.state.src} modalOpen={this.state.modalOpen}/>

            </Layout>
        )
    }
}

export default Query